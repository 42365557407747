.pageFooter {
    background-color: rgba(43, 61, 109, 1); 
    color: #ffffff; 
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%; 
    font-size: 18px;
}

@media (orientation: landscape) and (max-width: 736px) {
 .pageFooter{
    font-size: 14px !important; 
 }
}

@media (orientation: portrait) and (max-width: 736px) {
 .pageFooter{
    font-size: 12px !important; 
 }
}
