/* Testimonial.module.css */
.testimonialContainer {
    width: 100%;
    padding: 20px;
    margin: auto;
}

.testimonialItem {
    text-align: center;
    padding: 10px;
    margin: 5px;
    text-align: left;
}

.testimonialPhoto {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-bottom: 10px;
}

.staticList {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}

.listItem {
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    text-align: center;
    align-items: center;
}

.photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.nameStyle{
    font-size: 18px;
    display: flex;
    text-align: left;
    margin: 0; /* 清除上下或左右的多餘間距 */
    padding: 0; /* 清除內部間距 */
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 80%;
}

.commentStyle{
    display: flex;
    flex-direction: column;
}

.closeButton {
    margin-top: 10px;
    padding: 5px 10px;
    background: rgba(43, 61, 109, 0.8);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.customScrollbar {
    width: 90%;  
    height: 12px;
    background: #ccc;
    border-radius: 5px;
    outline: none;
    transition: background 0.3s ease-in-out;
}

.customScrollbar:hover, .customScrollbar:focus {
    background: #bbb;  
}

.customScrollbar::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #4CAF50;  
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
}

.customScrollbar::-webkit-slider-thumb:hover {
    background: #367c39;  
}

.customScrollbar::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
}
