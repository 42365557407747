.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* Higher if needed, to stay above other elements */
}

.customBackground {
    background-color: rgba(43, 61, 109, 0.8);
    height: 70px;
}

.button {
    font-size: 15px !important;
    align-items:center !important;
    color: rgba(255, 255, 255, 1) !important;
 }

.button:hover {
    color: rgba(255, 255, 255, 1) !important;
    text-decoration: none !important;  
}

@media (orientation: landscape) and (max-width: 736px) {
 .button{
    font-size: 12px !important; 
 }
}

@media (orientation: portrait) and (max-width: 736px) {
 .button{
    font-size: 9px !important; 
 }
}
    

.button2 {
    font-size: 15px !important;
    align-items:center !important;
    color: rgba(255, 255, 255, 1) !important;
}

.button2:hover {
    color: rgba(255, 255, 255, 1) !important; 
    text-decoration: none !important; 
}

@media (orientation: landscape) and (max-width: 736px) {
 .button2 {
    font-size: 12px !important; 
 }
}

@media (orientation: portrait) and (max-width: 736px) {
 .button2 {
    font-size: 8px !important; 
 }
}


li {
  cursor: pointer; /* 鼠标悬停时显示手型 */
}

.leftSlideMenu {
    position: absolute;
    top: 70px;
    left: 0;
    height: 525px;
    width: 300px; 
    background-color: #ffffff; /* 左拉選單背景顏色 */
    transform: translateX(-100％);
    opacity: 1;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 200;
    border-bottom: 20px solid rgba(43, 61, 109, 1);
}


@media (max-width: 736px) {
   .customBackground {
     background-color: rgba(43, 61, 109, 0.8);
     height: 50px;
    }

   .leftSlideMenu {
     top: 50px
   }
  .leftSlideMenu {
    height: 300px;
    width: 100%;  
  }
}

.leftSlideMenu2 {
    position: absolute;
    justify-content: space-between;
    top: 70px;
    left: 0;
    height: 100px;
    width: 100%; 
    background-color: #ffffff; /* 左拉選單背景顏色 */
    transform: translateX(-100％);
    opacity: 1;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 200;
    border-bottom: 20px solid rgba(43, 61, 109, 1);
}


.leftSlideItems {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
}

@media (orientation: landscape) and (max-width: 1000px) {
    .leftSlideMenu2 {
     top: 50px
   }
    .customBackground {
       background-color: rgba(43, 61, 109, 0.8);
       height: 50px;
    }
    .leftSlideItems {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .leftSlideItems li {
        margin: 0 10px !important; /* 添加項目之間的間距 */
        font-size: 16px !important;
        padding: 20px !important;
    }

    .leftSlideItems li::before {
        display: none !important;
    }

    .leftSlideItems li::after {
        left:45px !important;
        width: 50px  !important;
    }
}

@media (orientation: landscape) and (max-width: 736px) {
   html[lang="en"] .leftSlideItems li {
    font-size:14px !important;
    margin-left: 0 !important; 
    padding-left: 10px !important;
    margin-reight: 0 !important; 
  }  
}
    

.leftSlideItems li  {
    position: relative;
    padding: 30px;
    top: 50%;
    font-size: 30px;
    position: relative;
    font-family: Inter, sans-serif;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
}



@media (max-width: 736px) {
  .leftSlideItems li {
    font-size: 16px
  }
}

.leftSlideItems p  {
    position: relative;
    top: 50%;
    font-size: 30px;
    padding: 30px;
    position: relative;
    font-family: Inter, sans-serif;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
}



@media (max-width: 736px) {
  .leftSlideItems p {
    font-size: 16px
  }
}

.leftSlideItems li a {
    text-decoration: none; /* 移除下划线 */
    color: inherit; /* 保持与 li 一致的颜色 */
    display: block;
    width: 100%;
}



.leftSlideItems li::before {
    content: '>';
    position: absolute;
    top: 50%;
    left: 250px; /* 控制箭頭距離右邊的距離 */
    font-size: 30px;
    font-weight: bold;
    color: rgba(0, 0, 0, 1); /* 箭頭顏色 */
    transform: translateY(-50%);
} 


@media (max-width: 736px) {
  .leftSlideItems li::before {
     display: none
  }
}

.leftSlideItems p::before {
    content: '>';
    position: absolute;
    top: 50%;
    left: 250px; /* 控制箭頭距離右邊的距離 */
    font-size: 30px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.5); /* 箭頭顏色 */
    transform: translateY(-50%);
} 


@media (max-width: 736px) {
  .leftSlideItems p::before {
     display: none
  }
}

.leftSlideItems li::after {
    content: ""; /* 用空白內容來生成底線 */
    position: absolute;
    transform: translateX(-50%);
    left: 25%;
    bottom: 10px; /* 與文字的間距，可自行調整 */
    width: 25%; /* 底線的寬度，可根據需求調整 */
    height: 2px; /* 底線的高度 */
    background-color: rgba(43, 61, 109, 1); /* 底線的顏色 */
    opacity: 0.6; /* 底線的透明度 */
}

@media (max-width: 736px) {
  .leftSlideItems li::after {
     left: 45px;
     width: 50px;
  }
}

.leftSlideItems p::after {
    content: ""; /* 用空白內容來生成底線 */
    position: absolute;
    transform: translateX(-50%);
    left: 25%;
    bottom: 10px; /* 與文字的間距，可自行調整 */
    width: 25%; /* 底線的寬度，可根據需求調整 */
    height: 2px; /* 底線的高度 */
    background-color: rgba(43, 61, 109, 1); /* 底線的顏色 */
    opacity: 0.6; /* 底線的透明度 */
}

.leftSlideItems li:hover {
    color: rgba(0, 0, 0, 0.6); /* 改变文字颜色 */
}

.leftSlideItems li:hover::before {
    color: rgba(0, 0, 0, 0.6); /* 改变箭头颜色 */
}

.customnavbar {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 55% !important;
  margin: 0 20px 0 auto;
}

.navitem {
  position: relative;
  width: 295.02px !important; /* 固定寬度 */
  height: 70px !important; /* 固定高度 */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  font-family: Inter, sans-serif !important;
  font-size: 24px !important;
  font-weight: bold !important;
  white-space: nowrap; /* 防止換行 */
  overflow: visible !important; /* 隱藏超出的文字 */
  text-overflow: ellipsis;
}

.navitem2 {
  position: relative;
  width: 295.02px !important; /* 固定寬度 */
  height: 70px !important; /* 固定高度 */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  font-family: Inter, sans-serif !important;
  font-size: 26px !important;
  font-weight: bold !important;
  white-space: nowrap; /* 防止換行 */
  overflow: visible !important; /* 隱藏超出的文字 */
  text-overflow: ellipsis;
  top: 10px;
}

html[lang="zh"] .navitem {
  font-size: 26px !important; /* 中文的字體大小 */
}

html[lang="zh"] .navitem2 {
  font-size: 28px !important; /* 中文的字體大小 */
}

@media (max-width: 1300px) {
  .customnavbar{
    width: 65% !important;
  }
  .navitem {
    width: 200px !important;
  }
  .navitem2 {
    width: 200px !important;
  }

.dropdownMenu2{
    width: 210px ;
    padding-left: 5px !important;
 }
  html[lang="en"] .navitem {
    font-size: 20px !important; /* 中文的字體大小 */
  }
  html[lang="en"] .navitem2 {
    font-size: 22px !important; /* 中文的字體大小 */
  }
}

.navLink{
    position: relative;
    color: #ffffff ;
    text-decoration: none ;
    padding-bottom: 6px ;
}    

.navText{
    position: relative;
    color: #ffffff ;
    text-decoration: none ;
    padding-bottom: 10px ;
} 

.navText::after {
  content: ''; /* 生成底槓的虛擬元素 */
  position: absolute;
  left: 50%;             /* 左邊對齊中心點 */
  transform: translateX(-50%);  /* 將元素水平居中 */
  bottom: 10px;
  width: 60%;            /* 設置底槓的寬度為 80% */
  height: 4px;
  background-color: #ffffff;
}

.navLink::after {
  content: ''; /* 生成底槓的虛擬元素 */
  position: absolute;
  left: 50%;             /* 左邊對齊中心點 */
  transform: translateX(-50%);  /* 將元素水平居中 */
  bottom: 0;
  width: 60%;            /* 設置底槓的寬度為 80% */
  height: 4px;
  background-color: rgba(255, 255, 255, 0.35); /* 灰色的底槓 */
  transition: width 0.3s ease; /* 增加動畫效果 */
}

.navLink:hover::after {
  width: 100%; /* 使底槓在懸停時保持全寬 */
}

.dropdownMenu{
    z-index: 300;
    position: absolute;
    top: 75px;
    left: 0 ;
    min-width: 250px !important; /* 設置最小寬度 */
    min-height: 135px;
    background-color: rgba(43, 61, 109, 1);
    padding: 10px 0;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    opacity: 0; 
    visibility: hidden; 
    transition: opacity 0.3s ease, visibility 0.3s ease;
    border-left: 6px solid rgba(213, 168, 54, 1);
    list-style: none;
    color: #FFFFFF;
    text-align: left;
    font-size: 19px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;  
    justify-content: space-between; 
    height: 100%; 
    font-family: Inter, sans-serif;
    font-weight: bold;
}

.dropdownMenu2{
    z-index: 300;
    position: absolute;
    top: 75px;
    left: 0 ;
    min-width: 260px; /* 設置最小寬度 */
    min-height: 110px;
    background-color: rgba(43, 61, 109, 1);
    padding: 10px 0;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    opacity: 0; 
    visibility: hidden; 
    transition: opacity 0.3s ease, visibility 0.3s ease;
    border-left: 6px solid rgba(213, 168, 54, 1);
    list-style: none;
    color: #FFFFFF;
    text-align: left;
    font-size: 19px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;  
    justify-content: space-between; 
    height: 100%; 
    font-family: Inter, sans-serif;
    font-weight: bold;
}


.navitem:hover .dropdownMenu { 
    opacity: 1; 
    visibility: visible; 
}

.navitem:hover .dropdownMenu2 { 
    opacity: 1; 
    visibility: visible; 
}
