.logoImage {
    max-width: 100%
}

.button {
    font-size: 16px;
    align-items:center;
 }

.button2 {
    font-size: 16px;
    align-items:center;
}

@media (max-width: 1000px) {
  .button {
    display: none !important
  }

  .button2 {
    display: none !important
  }
}



